import { showNotification } from '@mantine/notifications';
import { HTTP_ERROR } from 'constants/messages';
import { useAuth } from 'hooks/use-auth';
import React from 'react';
import { CachePolicies, Provider } from 'use-http';


export const FetchProvider = ({ children }) => {
    const { token, signOut } = useAuth();
    const options = {
        cachePolicy: CachePolicies.NETWORK_ONLY,
        onError: (error) => {
            if (error.error.message === 'Failed to fetch') {
                showNotification({
                    title: HTTP_ERROR,
                    color: 'red',
                    autoClose: 5000,
                    disallowClose: true
                })
            }
        },
        retryOn: async ({ attempt, error, response }) => {
            return error != null && error.message === 'Failed to fetch';
        },
        retries: 1,
        interceptors: {
            request: async ({ options, url, path, route }) => {
                if (token) {
                    options.headers.Authorization = `Bearer ${token}`;
                }
                return options;
            },
            response: async ({ response }) => {
                switch (response.status) {
                    case 401:
                        if (!response.url.includes('login')) {
                            signOut(() => {
                                window.location.href = '/login';
                            });
                        }
                        break;
                    case 400:
                    case 500:
                    case 404:
                    case 422:
                        showNotification({
                            title: response.data.message,
                            color: 'red',
                            autoClose: 5000,
                            disallowClose: true
                        })
                        break;
                    default:
                        break;
                }
                return response;
            },
        },
    };

    return <Provider url={process.env.REACT_APP_API_URL} options={options}>{children}</Provider>;
};

