import React, { useState, useEffect } from 'react';
import { AuthContext } from 'context/auth-context'


export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const user = window.localStorage.getItem('session');
        if (user) {
            const session = JSON.parse(user);
            setUser(session);
            setToken(session.token);
        }
    }, []);

    const getUser = () => {
        const user = window.localStorage.getItem('session');
        if (user) {
            return JSON.parse(user);
        }
        return null;
    };

    const getToken = () => {
        const user = window.localStorage.getItem('session');
        if (user) {
            return JSON.parse(user)['token'];
        }
        return null;
    };

    const signIn = async (user, callback) => {
        setUser(user);
        setToken(user.token);
        window.localStorage.setItem('session', JSON.stringify(user));
        callback();
    };
    const signOut = async (callback) => {
        setUser(null);
        setToken(null);
        window.localStorage.removeItem('session');
        callback();
    };
    return <AuthContext.Provider
        value={{
            user: user ?? getUser(),
            signIn,
            signOut,
            token: token ?? getToken()
        }}>{children}</AuthContext.Provider>;
};