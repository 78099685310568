import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './app';
import '@inovua/reactdatagrid-enterprise/base.css'
import '@inovua/reactdatagrid-enterprise/theme/green-light.css'
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-tabs/style/react-tabs.css';

ReactDOM.render(
    <React.StrictMode><App /></React.StrictMode>,
    document.getElementById('root'),
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
