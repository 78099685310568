import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { AuthProvider } from 'context/auth-provider';
import { FetchProvider } from 'context/fetch-provider';

import React, { Suspense, useState, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModalsProvider } from '@mantine/modals';



const Counters = lazy(() => import('pages/counters'));
const OtherCounters = lazy(() => import('pages/other-counters'));
const Collections = lazy(() => import('pages/collections'));
const MainLayout = lazy(() => import('components/templates/main'));
const RequireAuth = lazy(() => import('lib/require-auth'));
const Clients = lazy(() => import('pages/clients'));
const Users = lazy(() => import('pages/users'));
const Locations = lazy(() => import('pages/locations'))
const IncomeTypes = lazy(() => import('pages/income-type'))
const EgressTypes = lazy(() => import('pages/egress-type'))
const ControlPanel = lazy(() => import('pages/control-panel'));
const Home = lazy(() => import('pages/home'));
const Login = lazy(() => import('pages/login'));
const Machines = lazy(() => import('pages/machines'));
const Products = lazy(() => import('pages/products'));
const Inventory = lazy(() => import('pages/Inventory'));
const Raffles = lazy(() => import('pages/raffles'));
const TaskPanel = lazy(() => import('pages/task-panel'));
const Tasks = lazy(() => import('pages/task'));
const Reports = lazy(() => import('pages/reports'));
const Turns = lazy(() => import('pages/turns'));
const InOut = lazy(() => import('pages/in-out'))


const App = () => {
  const [colorScheme, setColorScheme] = useState('light');
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  return <Suspense fallback="Cargando...">
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }}>
        <ModalsProvider>
          <NotificationsProvider>
            <AuthProvider>
              <FetchProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/' element={<RequireAuth children={<MainLayout />} />}>
                      <Route index element={<RequireAuth children={<ControlPanel />} />} />
                      <Route path='users' element={<RequireAuth roles={["admin"]} children={<Users />} />} />
                      <Route path='locations' element={<RequireAuth roles={["admin"]} children={<Locations />} />} />
                      <Route path='clients' element={<RequireAuth children={<Clients />} />} />
                      <Route path='machines' element={<RequireAuth children={<Machines />} />} />
                      <Route path='products' element={<RequireAuth children={<Products />} />} />
                      <Route path='inventory' element={<RequireAuth children={<Inventory />} />} />
                      <Route path='raffle' element={<RequireAuth children={<Raffles />} />} />
                      <Route path='collection' element={<RequireAuth children={<Collections />} />} />
                      <Route path='turns' element={<RequireAuth roles={["admin"]} children={<Turns />} />} />
                      <Route path='task' element={<RequireAuth children={<Tasks />} />} />
                      <Route path='counter' element={<RequireAuth children={<Counters />} />} />
                      <Route path='other-counter' element={<RequireAuth children={<OtherCounters />} />} />
                      <Route path='income-types' element={<RequireAuth roles={["admin"]} children={<IncomeTypes />} />} />
                      <Route path='egress-types' element={<RequireAuth roles={["admin"]} children={<EgressTypes />} />} />
                      <Route path='control-panel' element={<RequireAuth children={<ControlPanel />} />} />
                      <Route path='task-panel' element={<RequireAuth children={<TaskPanel />} />} />
                      <Route path='reports' element={<RequireAuth children={<Reports />} />} />
                      <Route path='in-out' element={<RequireAuth roles={["admin"]} children={<InOut />} />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </FetchProvider>
            </AuthProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  </Suspense>


}

export default App;
